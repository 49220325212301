import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion, useActiveLanguage } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();
  const { activeLanguage } = useActiveLanguage();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'JavaScript (ES6+)',
    'TypeScript',
    'CSS/SASS',
    'Tailwind',
    'React',
    'Express',
    'Node.js',
    'MongoDB',
    'Firebase',
    'Socket.IO',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">{activeLanguage === 'fr' ? 'Profile' : 'About Me'}</h2>

      <div className="inner">
        <StyledText>
          <div style={{ textAlign: 'justify' }}>
            {activeLanguage === 'fr' ? (
              <>
                <p>
                  Hello! Je m'appelle Ayman et je suis Développeur Full Stack autodidacte, avec une
                  solide formation en ingénierie de l'Ecole Centrale de Lyon et de l'Ecole Nationale
                  Supérieure des Arts et Métiers
                </p>
                <p>
                  Je maîtrise des technologies avancées comme{' '}
                  <span className="enhanced">React</span>,{' '}
                  <span className="enhanced">TypeScript</span> et{' '}
                  <span className="enhanced">Tailwind</span> me permettant de concevoir des
                  applications et sites web accessibles. À cela s'ajoutent{' '}
                  <span className="enhanced">Node.js</span>,{' '}
                  <span className="enhanced">Express</span> et{' '}
                  <span className="enhanced">MongoDB</span>, grâce auxquels j'ai la possibilité de
                  développer des systèmes back-end robustes.{' '}
                </p>
                <p>
                  Porté par une passion profonde pour le développement web et le monde de l'IT, je
                  suis constamment en quête de nouvelles connaissances et je me tiens à jour des
                  dernières tendances de la sphère web. Mon engagement à améliorer continuellement
                  mes compétences me prépare à relerver tous les défis du futur.
                </p>

                <p>Voici quelques technologies que j'ai utilisées récemment :</p>
              </>
            ) : (
              <>
                <p>
                  Hello! My name is Ayman and I am a self-taught Full Stack Developer with a solid
                  engineering background from Centrale School Lyon and Arts et Métiers School.
                </p>
                <p>
                  My technical toolkit includes advanced proficiency in{' '}
                  <span className="enhanced">React</span>,{' '}
                  <span className="enhanced">TypeScript</span> and{' '}
                  <span className="enhanced">Tailwind</span> for crafting accessible user
                  interfaces, alongside <span className="enhanced">Node.js</span>,{' '}
                  <span className="enhanced">Express</span> and{' '}
                  <span className="enhanced">MongoDB</span> for developing back-end systems.
                </p>
                <p>
                  Driven by a deep passion for web development and technology, I am commited to
                  continuous learning and keeping up with industry trends. My dedication to enhance
                  my skills keeps me ready to tackle the challenges of tomorrow.
                </p>

                <p>Here are a few technologies I’ve been working with recently:</p>
              </>
            )}
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
