import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig, email } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion, useActiveLanguage } from '@hooks';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();
  const { activeLanguage } = useActiveLanguage();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading overline">
        {activeLanguage === 'fr' ? 'Et ensuite?' : 'What’s Next?'}
      </h2>

      <h2 className="title">{activeLanguage === 'fr' ? 'Restons en contact' : 'Get In Touch'}</h2>

      <p>
        {activeLanguage === 'fr'
          ? 'Si vous souhaitez discuter d’une opportunité professionnelle, collaborer ensemble, me poser une question ou simplement dire bonjour, n’hésitez pas à me contacter. Je ferai de mon mieux pour vous répondre rapidement!'
          : 'If you want to discuss a job opportunity, build something, connect or just want to say hi, feel free to contact me. I’ll try my best to get back to you!'}
      </p>

      <a className="email-link" href={`mailto:${email}`}>
        {activeLanguage === 'fr' ? 'Contactez-moi' : 'Contact Me'}
      </a>
    </StyledContactSection>
  );
};

export default Contact;
