/* eslint-disable no-duplicate-imports */
import React from 'react';
import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

export const LanguageContext = createContext({ activeLanguage: 'fr', setActiveLangauge: () => {} });

export default function LanguageProvider({ children }) {
  const [activeLanguage, setActiveLangauge] = useState('fr');
  const value = { activeLanguage, setActiveLangauge };
  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
}

LanguageProvider.propTypes = {
  children: PropTypes.node,
};
