import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          transform="translate(-2.5,-3)"
          d="M35.79,54.51l-3.12,8.77h-4.5l10.79-30.56h5.62l11.25,30.56h-4.82l-3.22-8.77h-12ZM46.68,50.67c-2.77-7.73-4.33-11.97-5.01-14.38h-.04c-.75,2.67-2.49,7.77-4.72,14.38h9.77Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
