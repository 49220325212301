import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageProvider';

export default function useActiveLanguage() {
  const context = useContext(LanguageContext);

  if (context === undefined) {
    throw new Error('useActiveLanguage must be used inside a LanguageProvider');
  }

  return { activeLanguage: context?.activeLanguage, setActiveLanguage: context?.setActiveLangauge };
}
