import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />

        <path
          transform="translate(-2,-3)"
          d="M35.79,54.51l-3.12,8.77h-4.5l10.79-30.56h5.62l11.25,30.56h-4.82l-3.22-8.77h-12ZM46.68,50.67c-2.77-7.73-4.33-11.97-5.01-14.38h-.04c-.75,2.67-2.49,7.77-4.72,14.38h9.77Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
